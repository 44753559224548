<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title style="width: 100%; position: absolute; left: 0; height: 0;" >About Raro Guide (v{{ version }})</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="container">

        <p>Raro Guide is developed by Duane Malcolm with the much appreciated help of Fletcher Melvin and Tamati Iro.</p>

        Beta Warning

        <p>CONTACT ME</p>

        <h2>Future Work</h2>
        <ul>
          <li>Iron out the kinks and bugs.</li>
          <li>Names for the rings. Currently, the names are based on the Maori letter with either "tai" or "rua" tacked on the end.</li>
          <li>An app for Apple devices.</li>
          <li>Add more polish to the user interface and experience.</li>
          <li>Searchable place locations, e.g., shops, schools, halls, etc...</li>
        </ul>

        <h2>What's New in v 0.001</h2>
        <ul>
          <li>Everything you see</li>
        </ul>

<!--        <h2>Addressing Rarotonga</h2>-->

<!--        <p>The aim of this project is to address a problem on Rarotonga, which is the lack of addresses.</p>-->

<!--        <p>Addresses are useful for finding places, deliveries, </p>-->

<!--        <p>ADVANTAGES NOVEL, etc..</p>-->

<!--        <p>-->
<!--          <b>Road Numbering:</b>-->
<!--          The standard way to address a place is to use road names and number.-->
<!--          For Rarotonga, this is significant undertaking which has been tried a few times in the past.-->
<!--          However, there are disadvantages to this approach:-->
<!--        </p>-->
<!--        <ol>-->
<!--          <li>On-going maintenance - when a new road is established, it needs to be named, numbered and added to a database.</li>-->
<!--          <li>Constrained by the location of roads.</li>-->
<!--        </ol>-->

      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';


export default  {
  name: 'About',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage },
  data() {
    return {
      version: "0.001",
    };
  },



}
</script>

<style scoped>
.container {
  width: 720px;
  max-width: 100%;

  align-content: center;
  text-align: justify;
  font-family: serif;

  position: absolute;
  padding: 2em;
  padding-bottom: 96px;
}

.container p {
  font-size: 16px;
  line-height: 22px;

  color: #6e0000;

  margin: 0;
  padding-bottom: 1em;
}

</style>